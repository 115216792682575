import React from "react";
import "./App.scss";
import Layout from "./pages/Layout";
import Router from "./router/Router";
import { PlanProvider } from "./context/PlanProvider";

function App(props: any) {
  return (
    <PlanProvider>
      <Layout {...props}>
        <Router />
      </Layout>
    </PlanProvider>
  );
}

export default App;
