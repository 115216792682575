import { CURRENT_LANGUAGE } from '../constants/types';

function language(state = '', action: any = {}) {
  switch (action.type) {
    case CURRENT_LANGUAGE:
      return action.currentLanguage;
    default:
      return state;
  }
}

export { language };
