import React, { useState } from "react";
import { Typography, Select, Button, Space } from "antd";
import EmbedBox from "../../components/insurance/EmbedBox";
import { useNavigate } from "react-router-dom";
import { insureBullets } from "../../const/ecom";

const { Title, Text } = Typography;
const { Option } = Select;

const ProductDetail = () => {
  const [quantity, setQuantity] = useState(1);
  const [insuranceAdded, setInsuranceAdded] = useState(false);

  const navigate = useNavigate();

  const handleQuantityChange = (value: number) => {
    setQuantity(value);
  };

  const toggleInsurance = () => {
    setInsuranceAdded(!insuranceAdded);
  };

  return (
    <div className="product-detail">
      <div className="product-image">
        <img src="/poco-phone.png" alt="POCO C51" />
        <div className="image-dots">
          <span className="dot"></span>
          <span className="dot active"></span>
          <span className="dot"></span>
          <span className="dot"></span>
        </div>
      </div>

      <div className="product-info">
        <Title level={4}>POCO C51 (Royal Blue,4GB RAM,64GB Storage)</Title>
        <Text className="brand" type="secondary">
          Brand: POCO
        </Text>
        <div className="price-info">
          <Text className="current-price">₹25,000</Text>
          {/* <Text className="discount">(72% OFF)</Text> */}
        </div>
        {/* <Text type="secondary" className="mrp">
          MRP <span className="strike-through"> ₹20,000.00</span>
        </Text> */}
      </div>

      {/* <div className="quantity-selector">
        <Text className="label">Quantity</Text>
        <Select
          defaultValue={1}
          style={{ width: 78, height: 36 }}
          onChange={handleQuantityChange}
        >
          <Option value={1}>01</Option>
          <Option value={2}>02</Option>
          <Option value={3}>03</Option>
        </Select>
      </div> */}

      <div className="insurance">
        <EmbedBox
          heading={`Protect You phone for just ₹825`}
          subtitle="01 yr Extended Warranty by Bharatsure"
          bulletPoints={insureBullets}
          onClick={toggleInsurance}
          isSelected={insuranceAdded}
        />
      </div>
      <div className="summary">
        <div className="total-section">
          <div className="total">
            <Text className="fs-16">Total:</Text>
            <Text strong className="fs-16">
              ₹{insuranceAdded ? "25,825" : "25,000"}
            </Text>
          </div>
          {/* <Text type="secondary" className="fs-14 saved">
            <span>You Save:</span>
            <span className="amt">₹200</span>
          </Text> */}
        </div>

        <Text className="delivery-info fs-16">
          FREE Prime Delivery <strong>20-26 February</strong>
        </Text>

        <div className="action-buttons">
          <button className="add-to-cart outline">Add To Cart</button>
          <button
            className="buy-now primary"
            onClick={() => navigate("/cart", { replace: true })}
          >
            Buy Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
