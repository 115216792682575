import React, { useState } from "react";
import "./_tabs.scss";

const ListingTabs = () => {
  const [activeTab, setActiveTab] = useState("all");

  const tabs = [
    { key: "all", label: "All" },
    { key: "redmi", label: "Redmi" },
    { key: "samsung", label: "Samsung" },
    { key: "poco", label: "POCO" },
    { key: "apple", label: "Apple" },
  ];

  const handleTabClick = (key: string) => {
    setActiveTab(key);
  };

  return (
    <div className="listing-tabs">
      {tabs.map((tab) => (
        <button
          key={tab.key}
          className={`tab-button ${activeTab === tab.key ? "active" : ""}`}
          // onClick={() => handleTabClick(tab.key)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default ListingTabs;
