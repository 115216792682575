import React from "react";
import ProductListBox from "../../components/listing/ProductListBox";
import { useOrders } from "../../context/PlanProvider";
import EmbedBox from "../../components/insurance/EmbedBox";
import { insureBullets } from "../../const/ecom";
import NoItem from "../../components/common/NoItem";
import { useNavigate } from "react-router-dom";

const Orders = () => {
  const { hasOrders } = useOrders();
  const navigate = useNavigate();
  return (
    <>
      {hasOrders && (
        <div className="orders-page">
          <ProductListBox
            name="POCO C51 (Royal Blue,4GB RAM,64GB Storage)"
            price="25,825"
            brand="poco"
            img="/mob1.png"
          />
          <EmbedBox
            heading={`Protect You phone for just ₹825`}
            subtitle="01 yr Extended Warranty by Bharatsure"
            background="#F0F7FF"
            bulletPoints={insureBullets}
            showButton={false}
          />
        </div>
      )}
      {!hasOrders && (
        <NoItem
          label="No orders yet"
          btnCTA={
            <button
              className="primary text-white"
              onClick={() => {
                navigate("/listing");
              }}
            >
              Get Started
            </button>
          }
        />
      )}
    </>
  );
};

export default Orders;
