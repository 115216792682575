import insPlus from "../../assets/payswift/Insurance+.png";

export const routeBasedHeader: any = {
  home: {
    "": {
      name: "Payswift",
      logo: true,
    },
    insurance: {
      name: "Insurance+",
      // customImg: insPlus,
      logo: true,
    },
    listing: {
      title: "Phones",
      back: true,
      backLink: "/",
    },
    product: {
      title: "Phones",
      back: true,
      backLink: "/listing",
    },
    cart: {
      title: "Cart",
      back: true,
      backLink: "/product",
    },
    address: {
      title: "Address",
      back: true,
      backLink: "/cart",
    },
    payment: {
      back: true,
      backLink: "/cart",
      blank: true,
    },
    "pay-success": {
      blank: true,
    },
    orders: {
      title: "Your Order",
      back: true,
      backLink: "/",
    },
  },

  payswift: {
    "": {
      name: "Payswift",
      logo: true,
    },
    insurance: {
      name: "Insurance+",
      // customImg: insPlus,
      logo: true,
    },
    "select-plan": {
      title: "Plans",
      back: true,
      backLink: "/insurance",
    },
    "fill-details": {
      title: "Details",
      back: true,
      backLink: "/select-plan",
    },
    cart: {
      title: "Cart",
      back: true,
      backLink: "/fill-details",
    },
    payment: {
      back: true,
      backLink: "/cart",
      blank: true,
    },
    "pay-success": {
      blank: true,
    },
    "my-policies": {
      title: "My Policies",
      back: true,
      backLink: "/insurance",
    },
  },
};
