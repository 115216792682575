import React from "react";
import { Badge, Space } from "antd";
import "./_action.scss";

interface Props {
  children?: React.ReactNode;
  icon: React.ReactNode;
  title: string;
  iconProps?: React.HTMLAttributes<HTMLDivElement> & {
    className?: string;
    align?: "left" | "center" | "right";
  };
  titleProps?: React.HTMLAttributes<HTMLDivElement> & {
    className?: string;
    align?: "left" | "center" | "right";
  };
  outerProps?: React.HTMLAttributes<HTMLDivElement> & {
    className?: string;
    noIconBorder?: boolean;
    padding?: string;
  };
  badge?: string;
}

const ActionTile = ({
  children,
  icon,
  title,
  outerProps = { noIconBorder: false, padding: "1rem" },
  iconProps = { align: "center" },
  titleProps = { align: "center" },
  badge,
}: Props): JSX.Element => {
  const { noIconBorder, ...restOuterProps } = outerProps;

  return (
    <Space
      direction="vertical"
      rootClassName="action-tile-box"
      style={{ padding: outerProps.padding || "1rem" }}
    >
      <div className="outer" {...restOuterProps}>
        <div
          className={`icon ${noIconBorder ? "noborder" : ""} ${
            iconProps.align
          }`}
          {...iconProps}
        >
          <Badge count={badge} className="badge" />
          {icon}
        </div>
        <div className={`title ${titleProps.align}`} {...titleProps}>
          {title}
        </div>
      </div>
      {children && <div className="child">{children}</div>}
    </Space>
  );
};

export default ActionTile;
