import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./_styles.scss";
import OrderConfirm from "../../components/confirmation/OrderConfirm";

const PaySuccess: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/orders", { replace: true });
    }, 3000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="payment-success pointer" onClick={() => navigate("/orders")}>
      <OrderConfirm confirmTitle="Order Confirmed" />
    </div>
  );
};

export default PaySuccess;
