import React from "react";
import "./_payswift.scss";
import avatar1 from "../../assets/payswift/Ellipse-1084.png";
import avatar2 from "../../assets/payswift/Ellipse-1083.png";
import avatar3 from "../../assets/payswift/Ellipse-1082.png";

const avatars = [avatar1, avatar2, avatar3];

const StandardHealthPlan = () => {
  return (
    <div className="shp">
      <p className="subtitle">Exclusively curated</p>
      <h2 className="title">Standard Health Plan</h2>
      <div className="covered-info">
        <div className="avatars">
          {avatars.map((avatar, index) => (
            <img
              src={avatar}
              alt={`Avatar ${index + 1}`}
              className="avatar"
              key={index}
            />
          ))}
        </div>
        <p className="covered-text">
          <strong>20L+</strong> are already covered
        </p>
      </div>
    </div>
  );
};

export default StandardHealthPlan;
