import React from "react";
import "./_button.scss";
interface ButtonProps {
  text: string;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const Button = ({ text, className, disabled, onClick }: ButtonProps) => {
  return (
    <button
      disabled={disabled}
      className={`b-button ${className} ${disabled ? "disabled" : ""}`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default Button;
