import React from "react";
import { Typography, Space } from "antd";
import { useNavigate } from "react-router-dom";
import "./_payswift.scss";
import partyPopper from "../../assets/payswift/party_popper.svg";

const { Title, Text } = Typography;

const PaySuccessPolicy: React.FC = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/payswift/my-policies", { replace: true });
  };

  return (
    <div className="payment-success-policy">
      <Space direction="vertical" align="center" size="large">
        <img src={partyPopper} alt="Congratulations" />
        <Title level={3}>Congratulations!</Title>
        <Text>You & your family got Insured</Text>
        <button className="go-home" onClick={goToHome}>
          View policy
        </button>
      </Space>
    </div>
  );
};

export default PaySuccessPolicy;
