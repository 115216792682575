import React from "react";
import {
  HomeOutlined,
  CompassOutlined,
  BookOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./_nav.scss";

const Nav = () => {
  const navigate = useNavigate();

  return (
    <div className="nav">
      <div className="nav-wrapper">
        <div className="nav-item active" onClick={() => navigate("/")}>
          <HomeOutlined className="nav-icon" />
          <span className="nav-label">Home</span>
        </div>
      </div>
      <div className="nav-item" onClick={() => navigate("/browse")}>
        <CompassOutlined className="nav-icon" />
        <span className="nav-label">Browse</span>
      </div>
      <div className="nav-item" onClick={() => navigate("/saved")}>
        <BookOutlined className="nav-icon" />
        <span className="nav-label">Saved</span>
      </div>
      <div className="nav-item" onClick={() => navigate("/profile")}>
        <UserOutlined className="nav-icon" />
        <span className="nav-label">Profile</span>
      </div>
    </div>
  );
};

export default Nav;
