import React, { useState } from "react";
import { Typography, Steps, Divider } from "antd";
import ProductListBox from "../../components/listing/ProductListBox";
import EmbedBox from "../../components/insurance/EmbedBox";
import { insureBullets } from "../../const/ecom";
import { useNavigate } from "react-router-dom";

const { Title, Text, Link } = Typography;
const { Step } = Steps;

const Cart = () => {
  const [quantity, setQuantity] = useState(1);
  const [insuranceAdded, setInsuranceAdded] = useState(true);
  const navigate = useNavigate();

  const handleQuantityChange = (value: number) => {
    setQuantity(value);
  };

  const handleDelete = () => {
    console.log("Delete clicked");
  };

  const toggleInsurance = () => {
    setInsuranceAdded(!insuranceAdded);
  };

  const handleSubmit = () => {
    localStorage.setItem('amount', insuranceAdded ? "25,825" : '25,000')
    navigate("/address");
  };

  return (
    <div className="cart-page">
      <Steps responsive={false} current={0} className="cart-steps">
        <Step title="Cart" />
        <Step title="Address" />
        <Step title="Payment" />
      </Steps>

      <div className="cart-items">
        <ProductListBox
          name="POCO C51 (Royal Blue,4GB RAM,64GB Storage)"
          price={'25,000'}
          brand="POCO"
          img="/mob1.png"
          showCartControls={true}
          quantity={quantity}
          onQuantityChange={handleQuantityChange}
          onDelete={handleDelete}
        />
      </div>
      <Divider className="separator" style={{marginBottom: '24px'}} />


      <EmbedBox
        heading="Protect You phone for just ₹825"
        subtitle="01 yr Extended Warranty by Bharatsure"
        background="#EAFEE9"
        bulletPoints={insureBullets}
        onClick={toggleInsurance}
        isSelected={insuranceAdded}
      />
{/*
      <div className="cart-divider">
        <Divider className="divider" />
      </div>
      <button className="add-products-button outline">Add Products</button> */}

      <Divider className="separator" />

      <div className="price-details">
        <Title level={5}>Price Details</Title>
        <div className="price-item">
          <Text>Total Price (MRP)</Text>
          <Text>₹25,000</Text>
        </div>
        {insuranceAdded && <div className="price-item">
          <Text>Insurance</Text>
          <Text>₹825</Text>
        </div>}
        <div className="price-item total">
          <Text className="fs-16">Total payable</Text>
          <Text className="fs-24" strong>
          {insuranceAdded ? "₹25,825" : '₹25,000'}
          </Text>
        </div>
      </div>

      <div className="terms-submit">
        <Text type="secondary" className="redirect-info">
          You'll be redirected to a secure payment gateway to complete your
          purchase.
        </Text>

        <Text type="secondary" className="terms-info">
          Please read our <Link href="#">Terms and Conditions</Link> before
          purchase.
        </Text>

        <button className="proceed-button primary" onClick={handleSubmit}>
          Proceed
        </button>
      </div>
    </div>
  );
};

export default Cart;
