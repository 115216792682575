import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Landing from "../pages/Landing";
import Components from "../pages/Components";
import PaySuccess from "../pages/buymore/PaySuccess";
import Home from "../pages/buymore/Home";
import Listing from "../pages/buymore/Listing";
import ProductDetail from "../pages/buymore/ProductDetail";
import Cart from "../pages/buymore/Cart";
import Address from "../pages/buymore/Address";
import Orders from "../pages/buymore/Orders";
import MyPolicies from "../pages/payswift/MyPolicies";
import FillDetails from "../pages/payswift/FillDetails";
import InsurancePlus from "../pages/payswift/InsurancePlus";
import PlanSelection from "../pages/payswift/SelectPlan";
import PayCart from "../pages/payswift/Cart";
import PaymentMethods from "../pages/Payment";
import PaySuccessPolicy from "../pages/payswift/PaySuccess";

const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="/welcome" element={<Landing />} />
      <Route path="/" element={<Home />} />

      <Route path="*" element={<Home />} />
      <Route path="listing" element={<Listing />} />
      <Route path="product" element={<ProductDetail />} />
      <Route path="cart" element={<Cart />} />
      <Route path="address" element={<Address />} />

      <Route path="insurance" element={<InsurancePlus />} />

      <Route path="payswift">
        <Route path="select-plan" element={<PlanSelection />} />
        <Route path="insurance" element={<InsurancePlus />} />
        <Route path="fill-details" element={<FillDetails />} />
        <Route path="cart" element={<PayCart />} />
        <Route path="payment" element={<PaymentMethods />} />
        <Route path="pay-success" element={<PaySuccessPolicy />} />
        <Route path="my-policies" element={<MyPolicies />} />
      </Route>
      <Route path="to-payment" element={<Navigate to="/payment" replace />} />
      <Route path="payment" element={<PaymentMethods />} />
      <Route path="pay-success" element={<PaySuccess />} />
      <Route path="orders" element={<Orders />} />

      {/* Components */}
      <Route path="/components/usage" element={<Components />} />
    </Routes>
  );
};

export default Router;
