import React, { useState } from "react";
import { Card, Radio } from "antd";
import BenefitDrawer from "../payswift/BenefitDrawer";
import { exclusionsSet, inclusionsSet } from "../../const/ecom";

interface Props {
  title: string;
  value: string;
  leftBoxAmount: string;
  rightBoxAmount: string;
  description: string;
  details: string;
  checked?: string;
  onClick?: () => void;
  inclusions?: string[];
  exclusions?: string[];
}

const SelectBox: React.FC<Props> = ({
  title,
  value,
  leftBoxAmount,
  rightBoxAmount,
  description,
  details,
  checked,
  onClick,
  inclusions = inclusionsSet,
  exclusions = exclusionsSet,
}) => {
  const [showBenefits, setShowBenefits] = useState(false);

  const handleViewBenefits = () => {
    setShowBenefits(true);
  };

  const handleCloseBenefits = () => {
    setShowBenefits(false);
  };

  return (
    <>
      <Card
        className="select-card"
        onClick={onClick}
        title={title}
        extra={<Radio checked={checked === value} />}
        actions={[
          <div className="card-box">
            <div className="left-box">
              <div className="fdetails">Sum Insured</div>
              <span className="amount">{leftBoxAmount}</span>
            </div>
            <div className="right-box">
              <div className="fdetails">Starting Premium</div>
              <div>
                <span className="amount">₹{rightBoxAmount}</span>
                <span className="per-month">/month</span>
              </div>
            </div>
          </div>,
        ]}
      >
        <p className="desc">
          {description}{" "}
          <span className="view-link" onClick={handleViewBenefits}>
            View benefits
          </span>
        </p>
      </Card>
      <BenefitDrawer
        visible={showBenefits}
        onClose={handleCloseBenefits}
        inclusions={inclusions}
        exclusions={exclusions}
      />
    </>
  );
};

export default SelectBox;
