import React from "react";
import ProductListBox from "../../components/listing/ProductListBox";
import ListingTabs from "../../components/tabs";
import { listings } from "../../const/ecom";
import { useNavigate } from "react-router-dom";

const Listing = () => {
  const navigate = useNavigate();
  return (
    <div className="listing">
      <ListingTabs />
      <div className="product-list">
        {listings.map((product) => (
          <ProductListBox
            key={product.name}
            name={product.name}
            price={product.price}
            brand={product.brand}
            img={product.img}
            onClick={() => {
              product?.clickable && navigate("/product", {
                replace: true,
              });
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Listing;
