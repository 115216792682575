import React from "react";
import "./_section.scss";

interface Props {
  title: string;
  children: React.ReactNode;
}

const Section = ({ title, children }: Props) => {
  return (
    <div className="section">
      <div className="section-title">{title}</div>
      {children}
    </div>
  );
};

export default Section;
