import React from "react";
import { Badge, Card, Divider, Flex, Space, Typography } from "antd";
import "../scss/_components.example.scss";
import ActionTile from "../components/common/Actions/ActionTile";
import SubTitle from "../components/common/typography/SubTitle";
import SideNavBox from "../components/nav/SideNavBox";
import WithImage from "../components/cards/WithImage";
import SelectBox from "../components/plans/SelectBox";
import DetailBullet from "../components/plans/DetailBullet";
import Section from "../components/section/Section";
import ProductListBox from "../components/listing/ProductListBox";
import EmbedBox from "../components/insurance/EmbedBox";
import OrderConfirm from "../components/confirmation/OrderConfirm";
import TravelCard from "../components/cards/TravelCard";
import TravelPack from "../components/cards/TravelPack";

const Components = () => {
  document.title = "Components";
  const [plan, setPlan] = React.useState<string>("");
  const selectPlan = (value: string) => {
    setPlan(value);
  };
  const insureBullets = [
    "One-time deductible of 5% will be charged at the time of repair ",
    "Protects the device from all accidents",
    "Not covered in brand warranty",
    "Starts from Day 0 of sale ",
    "The plan can be purchased only for new devices bought on this platform and WITHIN 9 MONTHS of device purchase. You can purchase this plan only for devices which have AT LEAST 1-YEAR manufacturer's warranty valid in India.  ",
  ];
  return (
    <div className="components">
      <Typography.Title level={3} className="title">
        Components
      </Typography.Title>

      <SubTitle title="PaySwift" />
      <div className="space"></div>
      <Flex wrap="wrap" gap="3em" justify="center">
        <Badge.Ribbon
          text="Landing"
          color="rgb(10 96 242)"
          rootClassName="ribbon"
        >
          <Card className="card">
            <ActionTile
              icon={<img src="/ri_qr-code-line.svg" alt="Scan QR" />}
              title="Scan QR"
            />
          </Card>
        </Badge.Ribbon>
        <Badge.Ribbon
          text="Options"
          color="rgb(10 96 242)"
          rootClassName="ribbon"
        >
          <Card className="card">
            <ActionTile
              icon={
                <img
                  src="/healthicons_health-outline.svg"
                  alt="health insurance"
                />
              }
              title="Health Insurance"
              outerProps={{ noIconBorder: true }}
              iconProps={{ align: "left" }}
              titleProps={{ align: "left" }}
            />
          </Card>
        </Badge.Ribbon>
        <Badge.Ribbon
          text="People"
          color="rgb(10 96 242)"
          rootClassName="ribbon"
        >
          <Card className="card">
            <ActionTile
              icon={
                <img
                  src="https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-PNG-Photos.png"
                  alt="Anupam"
                  width={50}
                  height={50}
                />
              }
              title="Anupam"
              outerProps={{ noIconBorder: true }}
            />
          </Card>
        </Badge.Ribbon>
        <Card className="card w-full">
          <SideNavBox
            items={[
              {
                key: "1",
                label: "Payment",
                icon: <img src="/ri_bank-line.svg" alt="Payment" />,
                sClass: "k1",
              },
              {
                key: "2",
                label: "Refer & Earn",
                icon: (
                  <img src="/ri_contacts-book-2-line.svg" alt="Refer & Earn" />
                ),
                sClass: "k2",
              },
            ]}
            cName="k1"
          />
        </Card>
        <Space size="large">
          <DetailBullet point="Emergency road ambulance " type="y" />
          <DetailBullet point="Psychiatric Illness " type="n" />
        </Space>
      </Flex>
      <Divider dashed />
      <SubTitle title="NomadNexa" />
      <Flex wrap="wrap" gap="3em" justify="center">
        <Card className="card">
          <WithImage
            img="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
            title="Test title"
            subtitle="Test subtitle, hey!"
            imgProps={{ width: 250, height: 150, alt: "image_card" }}
            descBoxProps={{ align: "left", hasLeftMetaLine: true }}
          />
        </Card>

        <TravelCard
          time={{
            departure: "21:15",
            arrival: "02:40",
            duration: "6h 55 mins",
          }}
          price="9450"
          name="Flight No.: 5G 8169"
          via="(1-Stop) BOM"
          img="/flight.png"
          imgProps={{
            width: 50,
            height: 50,
          }}
          startCityCode="DEL"
          endCityCode="DXB"
        />

        <TravelPack
          price={9450}
          pack="Comfort Plus"
          link="/book"
          points={[
            { key: "Cabin Bag", value: "8kgs" },
            { key: "Check In", value: "25kgs" },
            { key: "Cancellation", value: "Cancellation fee starting 3,500" },
          ]}
        />
        <Card className="card" style={{ background: "#30A363" }}>
          <OrderConfirm confirmTitle="Order Confirmed" />
        </Card>
        <Badge.Ribbon
          text="Quick Travel Options"
          color="rgb(10 96 242)"
          rootClassName="ribbon"
        >
          <Card className="card">
            <ActionTile
              icon={
                <img src="/plane_1.svg" alt="Anupam" width={50} height={50} />
              }
              title="Flights"
            />
          </Card>
        </Badge.Ribbon>
        <Space size="large" direction="vertical">
          <SelectBox
            title="Standard Health Plan"
            description="Secure yourself and your family with base health cover."
            rightBoxAmount="250"
            leftBoxAmount="3000000"
            checked={plan}
            value="1"
            details="adcas"
            onClick={() => selectPlan("1")}
          />
          <SelectBox
            title="Standard Health Plan 2"
            description="Secure yourself and your family with base."
            rightBoxAmount="450"
            leftBoxAmount="5000000"
            checked={plan}
            value="2"
            details="adcas"
            onClick={() => selectPlan("2")}
          />
        </Space>
        <Section title="Section">
          <Card className="section-card" style={{ width: "100%" }}>
            This is just a section
          </Card>
        </Section>
      </Flex>
      <Divider dashed />
      <SubTitle title="BuyMore" />
      <Flex wrap="wrap" gap="3em" justify="center">
        <Card className="card">
          <WithImage
            img="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
            title="Test title"
            subtitle="Test subtitle, hey!"
            imgProps={{ width: 109, height: 135, alt: "image_card" }}
          />
        </Card>
        <ProductListBox
          name="POCO C51 (Royal Blue,4GB RAM,64GB Storage)"
          price="5499"
          brand="poco"
          img="/mob1.png"
        />
        <EmbedBox
          heading="Protect Your phone for just ₹825"
          subtitle="01 yr Extended Warranty by Bharatsure"
          background="#EAFEE9"
          bulletPoints={insureBullets}
        />
      </Flex>
    </div>
  );
};

export default Components;
