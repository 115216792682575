import React from "react";
import { MenuOutlined } from "@ant-design/icons";
import "./_header.scss";
import { Sidebar } from "./Sidebar";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { routeBasedHeader } from "./routes";

interface Props {
  logo?: React.ReactNode | string;
  children?: React.ReactNode;
  title?: string;
  section?: string;
}

const Header: React.FC<Props> = ({ logo, children, title, section }: Props) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const goHome = () => {
    navigate("/");
  };

  const goBack = (link: string) => {
    if (title?.toLowerCase() !== "home") {
      navigate(`/${title?.toLowerCase().replace(/\s/g, "-")}${link}`);
    } else {
      navigate(link);
    }
  };

  const currentRoute =
    routeBasedHeader?.[title?.toLowerCase() || ""]?.[
      section?.toLowerCase() || ""
    ];

  return (
    <div className="header">
      {!currentRoute?.back && (
        <MenuOutlined className="menu-icon" onClick={toggleSidebar} />
      )}
      <div
        className={`header-content ${
          currentRoute && currentRoute.back ? "with-back" : ""
        }`}
      >
        {((currentRoute && currentRoute.logo) || !currentRoute) &&
          logo &&
          (typeof logo === "string" ? (
            <img src={logo} alt="logo" className="pointer" onClick={goHome} />
          ) : (
            logo
          ))}

        {currentRoute && currentRoute.customImg && (
          <img src={currentRoute.customImg} alt={currentRoute.title} />
        )}
        {currentRoute && currentRoute.back && (
          <ArrowLeftOutlined
            className="back-button"
            onClick={() => goBack(currentRoute?.backLink)}
          />
        )}
        {currentRoute && currentRoute.title && (
          <h1 className="title">{currentRoute.title}</h1>
        )}

        {children && <div className="child">{children}</div>}
      </div>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
    </div>
  );
};

export default Header;
