import React, { createContext, useContext, useState, useEffect } from "react";

const OrderContext = createContext<{
  hasOrders: boolean;
  updateHasOrders: (value: boolean) => void;
  hasPolicies: boolean;
  updateHasPolicies: (value: boolean) => void;
}>({
  hasOrders: false,
  updateHasOrders: () => {},
  hasPolicies: false,
  updateHasPolicies: () => {},
});

export const PlanProvider = ({ children }: { children: React.ReactNode }) => {
  const [hasOrders, setHasOrders] = useState<boolean>(false);
  const [hasPolicies, setHasPolicies] = useState<boolean>(false);

  const checkIfPolicyPurchased = () => {
    const hasOrders = localStorage.getItem("hasOrders");
    setHasOrders(!!hasOrders);
    const hasPolicies = localStorage.getItem("hasPolicies");
    setHasPolicies(!!hasPolicies);
  };

  const updateHasOrders = (value: boolean) => {
    setHasOrders(value);
    localStorage.setItem("hasOrders", value.toString());
  };

  const updateHasPolicies = (value: boolean) => {
    setHasPolicies(value);
    localStorage.setItem("hasPolicies", value.toString());
  };

  useEffect(() => {
    checkIfPolicyPurchased();
  }, []);

  return (
    <OrderContext.Provider
      value={{ hasOrders, updateHasOrders, hasPolicies, updateHasPolicies }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export const useOrders = () => useContext(OrderContext);
