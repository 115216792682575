import React from "react";
import { Input, Typography, Card } from "antd";
import ActionTile from "../../components/common/Actions/ActionTile";
import { SearchOutlined } from "@ant-design/icons";
import "./_styles.scss";
import Banner from "../../assets/buymore/headphones.png";
import { brandsHome, categoriesHome } from "../../const/ecom";
import { useNavigate } from "react-router-dom";
import Nav from "../../components/footer/Nav";

const { Title } = Typography;

const Home = () => {
  const navigate = useNavigate();
  return (
    <div className="home">
      <div className="search">
        <Input
          className="search-bar"
          prefix={<SearchOutlined />}
          placeholder="Search"
          allowClear
        />
      </div>

      <div className="banner">
        <img src={Banner} alt="Headphones 50% Off" />
      </div>
      <div className="content">
        <div className="categories">
          {categoriesHome.map(({ category, icon, clickable }, index) => (
            <ActionTile
              key={index}
              icon={<img src={icon} alt={category} />}
              title={category}
              outerProps={{
                onClick: () => {
                  clickable && navigate("/listing", { replace: true });
                },
              }}
            />
          ))}
        </div>
        <Title level={4}>Browse by Brands</Title>
        <div className="brands">
          {brandsHome.map(({ alt, src }, index) => (
            <div className="brand" key={index}>
              <Card
                key={index}
                cover={<img alt={alt} src={src} />}
                hoverable
                className="brand-card"
              />
              <div className="title">
                <div className="brand-name">{alt}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Nav />
    </div>
  );
};

export default Home;
