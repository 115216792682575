import { Menu } from "antd";
import React from "react";

interface Props {
  items: SideItem[];
  cName: string;
}

interface SideItem {
  key: string;
  label: string;
  icon: React.ReactNode;
  sClass: string;
}

const SideNavBox = ({ items, cName }: Props) => {
  return (
    <Menu
      theme="light"
      mode="inline"
      defaultSelectedKeys={["1"]}
      defaultOpenKeys={["k1"]}
      items={items}
      className={cName}
      style={{ height: "100%" }}
    />
  );
};

export default SideNavBox;
