import React from "react";
import { Empty, Typography, Button } from "antd";
import { BackwardOutlined } from "@ant-design/icons";
import "./_common.scss";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

const NoItem = ({
  label = "No items to show",
  img,
  btnCTA,
}: {
  label?: string;
  img?: string;
  btnCTA?: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const handleClick = () => navigate("/");
  return (
    <div className="no-item-box">
      {img && <img src={img} alt="No items" className="no-item-img" />}
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<Text className="no-item-text">{label}</Text>}
      />
      {btnCTA}
    </div>
  );
};

export default NoItem;
