import React from "react";
import { Col, Row } from "antd";
import "./_cards.scss";
import Button from "../common/button/Button";

interface Props {
  price: number;
  pack: string;
  link: string;
  points: Point[];
}

interface Point {
  key: string;
  value: string;
}

const TravelPack = ({ price, pack, link, points }: Props): JSX.Element => {
  return (
    <Col className="pack-card">
      <Row>
        <Col span={24}>
          <div className="pack-head">
            <Row className="top">
              <Col span={4} className="detail left">
                <div className="pack-title">{pack}</div>
                <div className="pack-subtitle">Fare Offered by Offline</div>
              </Col>
              <Col span={4} className="price right">
                <div className="time">₹{price}</div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row justify="space-between">
        <Col className="point-box">
          {points.map((point, index) => (
            <div key={index} className="point">
              <div className="left">{point.key}</div>
              <div className="right">{point.value}</div>
            </div>
          ))}
        </Col>
      </Row>
      <Row className="bottom">
        <Button
          text="Book Now"
          className="bprimary filled w-max pos-right"
          onClick={() => (window.location.href = link)}
        />
      </Row>
    </Col>
  );
};

export default TravelPack;
