import React, { useState } from "react";
import { Drawer } from "antd";
import DetailBullet from "../plans/DetailBullet";

interface BenefitDrawerProps {
  visible: boolean;
  onClose: () => void;
  inclusions: string[];
  exclusions: string[];
}

const BenefitDrawer: React.FC<BenefitDrawerProps> = ({
  visible,
  onClose,
  inclusions,
  exclusions,
}) => {
  const [activeTab, setActiveTab] = useState("inclusions");

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <Drawer
      title="Plan Details"
      placement="right"
      onClose={onClose}
      visible={visible}
      width={400}
      className="benefit-drawer"
    >
      <div className="drawer-tabs">
        <button
          className={`drawer-tab ${activeTab === "inclusions" ? "active" : ""}`}
          onClick={() => handleTabChange("inclusions")}
        >
          Inclusions
        </button>
        <button
          className={`drawer-tab ${activeTab === "exclusions" ? "active" : ""}`}
          onClick={() => handleTabChange("exclusions")}
        >
          Exclusions
        </button>
      </div>
      <div className="drawer-content">
        <div className="drawer-note">
          <p>
            <span role="img" aria-label="Info" className="info-icon">
              ⓘ
            </span>{" "}
            {activeTab === "inclusions"
              ? "These are the benefits covered under the product."
              : "These are the benefits not covered under the product."}
          </p>
        </div>
        {activeTab === "inclusions" && (
          <ul className="inclusions">
            {inclusions.map((item, index) => (
              <DetailBullet point={item} type="y" key={index} />
            ))}
          </ul>
        )}
        {activeTab === "exclusions" && (
          <ul className="exclusions">
            {exclusions.map((item, index) => (
              <DetailBullet point={item} type="n" key={index} />
            ))}
          </ul>
        )}
      </div>
    </Drawer>
  );
};

export default BenefitDrawer;
