import React, { useEffect } from "react";
import { Col, Image } from "antd";
import "./_confirm.scss";

interface OrderConfirmProps {
  confirmTitle: string;
  children?: React.ReactNode;
}

const OrderConfirm = ({
  confirmTitle,
  children,
}: OrderConfirmProps): JSX.Element => {
  useEffect(() => {
    const okIcon = document.querySelector(".ok-icon");
    if (okIcon) {
      okIcon.classList.add("animate-tick");
    }
  }, []);

  return (
    <Col span={24} className="order-confirm">
      <Image
        preview={false}
        src="/confirmed.svg"
        alt="confirmed"
        className="ok-icon"
      />
      <div className={`heading ${!children && "big"}`}>{confirmTitle}</div>
      {children}
    </Col>
  );
};

export default OrderConfirm;
