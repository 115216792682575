import React from "react";
import "./_select.scss";
interface Props {
  point: string;
  type: "y" | "n";
}

const DetailBullet = ({ point, type }: Props) => {
  return (
    <div className="detail-bullet">
      <img
        src={
          type === "y"
            ? "/carbon_checkmark-stroke.svg"
            : "/carbon_close-stroke.svg"
        }
        alt="check"
      />
      <span className="point">{point}</span>
    </div>
  );
};

export default DetailBullet;
