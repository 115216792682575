import React from "react";
import { Typography, Steps, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useOrders } from "../../context/PlanProvider";

const { Title, Text } = Typography;
const { Step } = Steps;

const Address = () => {
  const navigate = useNavigate();
  const { updateHasOrders } = useOrders();

  const handleContinue = () => {
    updateHasOrders(true);
    localStorage.removeItem('premium')
    navigate("/to-payment");
  };

  return (
    <div className="address-page">
      <Steps responsive={false} current={1} className="address-steps">
        <Step title="Cart" />
        <Step title="Address" />
        <Step title="Payment" />
      </Steps>

      <div className="address-card">
        <div className="address-info">
          <div className="name-section">
            <Text strong>Aman Sharma</Text>
            <Text className="address-type">Home</Text>
          </div>
          <Text className="address">
            Phase 8B, Industrial Area, Sector 74, Mohali, Punjab 140401,
            9100009999
          </Text>
        </div>
        <button className="change-address-button outline">
          Change Or Add Address
        </button>
      </div>

      <div className="button-section">
        <button className="continue-button primary" onClick={handleContinue}>
          Continue
        </button>
      </div>
    </div>
  );
};

export default Address;
