import { Typography } from "antd";
import React from "react";
import "./_typography.scss";

const SubTitle = ({
  title,
  className,
}: {
  title: string | React.ReactNode;
  className?: string;
}) => {
  return (
    <Typography.Title level={5} className={`subtitle ${className}`}>
      {title}
    </Typography.Title>
  );
};

export default SubTitle;
