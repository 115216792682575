import React from "react";
import { Card, Divider, Typography } from "antd";
import BenefitDrawer from "../payswift/BenefitDrawer";
import { exclusionsSet, inclusionsSet } from "../../const/ecom";
import "./_cards.scss";

const { Title, Text, Link } = Typography;

interface Props{
  premium?: string
}

const ProductCard = ({premium}: Props) => {
  const [showBenefits, setShowBenefits] = React.useState(false);
  const handleCloseBenefits = () => {
    setShowBenefits(false);
  };
  return (
    <Card className="product-card">
      <div className="product">
        <div className="title">
          <Title level={5}>Standard Health Plan</Title>
          <Text className="reco" type="secondary">
            (Recommended)
          </Text>
        </div>
        <Text className="description">
          Access to partnered gyms and studios & online fitness classes.{" "}
          <Link className="view-benefits" onClick={() => setShowBenefits(true)}>
            View benefits
          </Link>
        </Text>
      </div>

      <Divider />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Text type="secondary">Sum Insured</Text>
          <div>₹5,00,000</div>
        </div>
        <div>
          <Text type="secondary">Premium</Text>
          <div>₹{premium ?? localStorage?.getItem('premium') ?? '18,000'}</div>
        </div>
      </div>
      <BenefitDrawer
        visible={showBenefits}
        onClose={handleCloseBenefits}
        inclusions={inclusionsSet}
        exclusions={exclusionsSet}
      />
    </Card>
  );
};

export default ProductCard;
