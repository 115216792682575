import { Drawer, Menu } from "antd";
import userIcon from "../../assets/payswift/userp-icon.png";
import { useNavigate, useLocation } from "react-router-dom";
import { sidebarValues } from "../../const/ecom";
import { useEffect, useState } from "react";

export const Sidebar = ({
  sidebarOpen,
  toggleSidebar,
}: {
  sidebarOpen: boolean;
  toggleSidebar: () => void;
}) => {
  const navigate = useNavigate();
  const loc = useLocation();
  const [activeKey, setActiveKey] = useState<string>("");

  const handleOpen = (item: any) => {
    const path =
      "/" + (item?.url ?? item?.label?.toLowerCase().replace(/\s/g, "-"));
    navigate(path);
  };

  useEffect(() => {
    const selected = sidebarValues.find((item) =>
      loc.pathname.includes(
        item.url ?? item.label.toLowerCase().replace(/\s/g, "-")
      )
    );
    if (selected) {
      setActiveKey(selected.key);
    } else {
      setActiveKey("");
    }
  }, [loc.pathname]);

  return (
    <Drawer
      width={250}
      onClose={toggleSidebar}
      open={sidebarOpen}
      className={sidebarOpen ? "sidebar" : "sidebar closed"}
      style={{ height: "100vh", padding: 0 }}
      closable={false}
      placement="left"
    >
      <div className="profile">
        <div>
          <h2>Abhishek</h2>
          <a href="#">View Profile</a>
        </div>
        <img src={userIcon} alt="Profile Pic" />
      </div>
      <Menu
        theme="light"
        mode="inline"
        selectedKeys={[activeKey]}
        onClick={() => toggleSidebar()}
      >
        {sidebarValues.map((item) => (
          <Menu.Item
            key={item.key}
            icon={
              typeof item?.icon === "string" ? (
                <img src={item?.icon} alt={item.label} />
              ) : (
                item?.icon
              )
            }
            onClick={() => {
              item?.clickable && handleOpen(item);
            }}
          >
            {item.label}
          </Menu.Item>
        ))}
      </Menu>
      <div className="version">Version 1.1</div>
    </Drawer>
  );
};
