import React from "react";
import ProductCard from "../../components/cards/ProductCard";
import "./_payswift.scss";

const MyPolicies = () => {
  return (
    <div className="policy-page">
      <ProductCard />
    </div>
  );
};

export default MyPolicies;
