import React from "react";
import { Badge, Card, Divider, Flex, Space, Typography } from "antd";
import SubTitle from "../../components/common/typography/SubTitle";
import SelectBox from "../../components/plans/SelectBox";
import { useNavigate } from "react-router-dom";

const PlanSelection = () => {
  const [plan, setPlan] = React.useState<string>("");
  const navigate = useNavigate();

  const selectPlan = (value: string) => {
    setPlan(value);
  };

  const selectAndNext = () => {
    if (plan) {
      localStorage.setItem("selectedPlan", plan);
      navigate("/payswift/fill-details", { replace: true });
    }
  };

  return (
    <div className="plan-selection">
      <SubTitle title="Select Plan" className="title" />
      <Flex wrap="wrap" gap="3em" justify="center" className="mb-40">
        <Space size="large" direction="vertical">
          <SelectBox
            title="Standard Health Plan"
            description="Secure yourself and your family with base health cover."
            rightBoxAmount="1,500"
            leftBoxAmount="5,00,000"
            checked={plan}
            value="1"
            details="adcas"
            onClick={() => selectPlan("1")}
          />
          <SelectBox
            title="Super Top-up"
            description="Upgrade your base health cover by 30 Lacs"
            rightBoxAmount="250"
            leftBoxAmount="30,00,000"
            checked={plan}
            value="2"
            details="adcas"
            // onClick={() => selectPlan("2")}
          />
        </Space>
      </Flex>
      <Flex justify="center">
        <div className="next-button-container">
          <button
            className={`next-button ${plan ? "" : "disabled"}`}
            onClick={selectAndNext}
          >
            Next
          </button>
        </div>
      </Flex>
    </div>
  );
};

export default PlanSelection;
