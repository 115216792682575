import React from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import { useLocation } from "react-router-dom";
import BuyMore from "../assets/buymore/BuyMore.svg";

const Layout: React.FC = (props: any) => {
  const location = useLocation();

  const getAppDetails = () => {
    if (
      location.pathname.includes("/payswift") ||
      location.pathname === "/insurance"
    ) {
      return {
        title: "Payswift",
        link: "/payswift",
        section: location.pathname.split("/")[2],
      };
    }
    return {
      title: "Home",
      link: "/",
      section: location.pathname.split("/")[1],
    };
  };

  return (
    <div
      className={`layout ${
        location?.pathname === "/listing" ? "bg-gray-all" : ""
      }`}
    >
      <Header
        title={getAppDetails()?.title}
        section={getAppDetails()?.section}
        logo={BuyMore}
      />
      {props.children}
      <Footer />
    </div>
  );
};

export default Layout;
