import React from "react";
import Meta from "antd/es/card/Meta";
import "./_cards.scss";
import { AlignType, ImageProps } from "../../constants/types";

interface Props {
  img: string;
  title: string;
  subtitle: string;
  imgProps?: ImageProps;
  descBoxProps?: {
    align?: AlignType;
    hasLeftMetaLine?: boolean;
  };
}
const WithImage = ({ img, title, subtitle, imgProps, descBoxProps }: Props) => {
  const imgElement = imgProps ? (
    <img {...imgProps} alt="image_card" className="img" src={img} />
  ) : (
    <img alt="image_card" className="img" src={img} width={300} />
  );

  const hasLeftMetaLine = descBoxProps?.hasLeftMetaLine;
  const leftMetaLineStyle = hasLeftMetaLine
    ? { borderLeft: "2px solid #eee", marginTop: 5, marginBottom: 5 }
    : {};

  const metaElement = (
    <Meta
      className={`meta ${hasLeftMetaLine ? "with-left-line" : ""}`}
      style={leftMetaLineStyle}
      description={subtitle}
      title={
        <a className="title" href="#">
          {title}
        </a>
      }
    />
  );

  const descBoxAlignmentClass = `with-image ${descBoxProps?.align || "left"}`;

  return (
    <div className="with-image-card" onClick={() => console.log("Ok")}>
      {img && (
        <div className={descBoxAlignmentClass}>
          <div className="image-box">
            {imgElement}
            <div className="shadow"></div>
          </div>

          {metaElement}
        </div>
      )}
    </div>
  );
};

export default WithImage;
