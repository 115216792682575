import mdiCreditCardOutline from "../../assets/payswift/mdi_credit-card-outline.svg";
import bxSupport from "../../assets/payswift/bx_support.svg";
import mdiCogOutline from "../../assets/payswift/mdi_cog-outline.svg";
import mdiBellOutline from "../../assets/payswift/mdi_bell-outline.svg";
import healthIcon from "../../assets/payswift/healthicons_health-outline.svg";
import Rectangle41 from "../../assets/buymore/Rectangle-4-1.svg";
import Rectangle42 from "../../assets/buymore/Rectangle-4-2.svg";
import Rectangle43 from "../../assets/buymore/Rectangle-4-3.svg";
import Rectangle4 from "../../assets/buymore/Rectangle-4.svg";
import brands1 from "../../assets/buymore/brands-1.png";
import brands2 from "../../assets/buymore/brands-2.png";
import brands3 from "../../assets/buymore/brands-3.jpg";
import ordersIcon from "../../assets/buymore/order-box.svg";
// import { HomeOutlined } from "@ant-design/icons";

export const sidebarValues = [
  //   { key: "7", label: "Home", icon: <HomeOutlined style={{
  //     fontSize: "20px"
  //   }} />, clickable: true,
  //   url: "/",
  // },
  { key: "0", label: "Orders", icon: ordersIcon, clickable: true },
  { key: "1", label: "Payment", icon: mdiCreditCardOutline, clickable: false },
  { key: "2", label: "Refer & Earn", icon: mdiBellOutline, clickable: false },
  { key: "3", label: "Insurance", icon: healthIcon, clickable: true },
  {
    key: "4",
    label: "My Policies",
    icon: healthIcon,
    clickable: true,
    url: "payswift/my-policies",
  },
  { key: "5", label: "Notifications", icon: mdiBellOutline, clickable: false },
  { key: "6", label: "Settings", icon: mdiCogOutline, clickable: false },
  { key: "7", label: "Support", icon: bxSupport, clickable: false },
];

export const inclusionsSet = [
  "Day care treatments",
  "Pre/Post hospitalisation",
  "Initial waiting period",
  "Covid-19 coverage",
  "Alternate treatments",
  "Organ donor expenses",
  "Emergency road ambulance",
  "Internal Congenital",
  "Lasik Surgery",
];

export const exclusionsSet = [
  "OPD treatment",
  "Home hospitalisation",
  "Psychiatric Illness",
  "Cochlear Impant",
  "Infertility Treatments",
  "Cosmetic/Plastic surgery",
];

export const categoriesHome = [
  { category: "Men", icon: Rectangle4, clickable: false },
  { category: "Women", icon: Rectangle41, clickable: false },
  { category: "Phones", icon: Rectangle42, clickable: true },
  { category: "Watches", icon: Rectangle43, clickable: false },
];

export const brandsHome = [
  { alt: "Zara", src: brands1 },
  { alt: "Nike", src: brands2 },
  { alt: "Levi's", src: brands3 },
];

export const listings = [
  {
    name: "POCO C51 (Royal Blue,4GB RAM,64GB Storage)",
    price: "25,000",
    brand: "POCO",
    img: "/mob1.png",
    clickable: true,
  },
  {
    name: "Samsung Galaxy S20 FE 5G (Cloud Navy, 8GB RAM, 128GB Storage)",
    price: "26,800",
    brand: "SAMSUNG",
    img: "/mob-1.png",
    clickable: false,
  },
  {
    name: "Redmi A2 (Aqua Blue, 2GB RAM, 64GB Storage)",
    price: "5,499",
    brand: "REDMI",
    img: "/mob-2.png",
    clickable: false,
  },
  {
    name: "Samsung Galaxy M13 (Midnight Blue, 4GB, 64GB Storage)",
    price: "9,999",
    brand: "SAMSUNG",
    img: "/mob-3.png",
    clickable: false,
  },
];

export const insureBullets = [
  "Protects the device against mechanical and electrical failures",
  "Extension of brand warranty",
  "Coverage starts after expiry of the brand warranty",
  "Hassle-free claim and activation process. Only one repair is covered during the plan term of one year.",
  "This plan cannot be transferred to another person.",
];
