import React, { useState } from "react";
import { Form, Input, Select, Typography } from "antd";
import "./_payswift.scss";
import StandardHealthPlan from "../../components/payswift/StandarHead";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const FamilyMemberDetails = ({ type, index, form }: any) => {
  const fields = [
    {
      label: "First Name",
      name: "firstName",
      placeholder: "eg. Ajay",
      prefill: "Samay",
    },
    {
      label: "Last Name",
      name: "lastName",
      placeholder: "eg. Kumar",
      prefill: "Kumar",
    },
    {
      label: "Gender",
      name: "gender",
      placeholder: "Select",
      prefill: "Male",
      options: [
        { value: "male", label: "Male" },
        { value: "female", label: "Female" },
        { value: "other", label: "Other" },
      ],
    },
    {
      label: "Date of Birth",
      name: "dateOfBirth",
      type: "date",
      prefill: "01-01-1990",
      placeholder: "DD-MM-YYYY",
    },
  ];

  return (
    <div className={`${type}-details`}>
      <Title level={4}>{type.charAt(0).toUpperCase() + type.slice(1)}</Title>
      {fields.map((field, idx) => (
        <Form.Item
          key={idx}
          label={field.label}
          name={`${type}Details[${index}].${field.name}`}
          rules={[{ required: true, message: `${field.label} is required` }]}
          initialValue={field.prefill}
        >
          {field.options ? (
            <Select
              placeholder={field.placeholder}
              defaultValue={field.prefill}
            >
              {field.options.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Input placeholder={field.placeholder} />
          )}
        </Form.Item>
      ))}
    </div>
  );
};

const FillDetails = () => {
  const [form] = Form.useForm();
  const [spouseDetails, setSpouseDetails] = useState<any>([]);
  const [childDetails, setChildDetails] = useState<any>([]);
  const navigate = useNavigate();

  const handleSpouseAdd = () => {
    if (spouseDetails.length < 1) {
      setSpouseDetails([
        ...spouseDetails,
        {
          firstName: "Ajay Kumar",
          lastName: "Kumar",
          gender: "",
          dateOfBirth: "",
        },
      ]);
    }
  };

  const handleChildAdd = () => {
    if (childDetails.length < 3) {
      setChildDetails([
        ...childDetails,
        {
          firstName: "Ajay Kumar",
          lastName: "Kumar",
          gender: "",
          dateOfBirth: "",
        },
      ]);
    }
  };

  const onFinish = (values: any) => {
    const dataToStore = {
      ...values,
      spouseDetails: spouseDetails.map((item: any) => ({ ...item })),
      childDetails: childDetails.map((item: any) => ({ ...item })),
    };
    localStorage.setItem("submittedDetails", JSON.stringify(dataToStore));
    navigate("/payswift/cart", { replace: true });
  };

  const formItems = [
    {
      label: "First Name",
      name: "firstName",
      placeholder: "eg. Ajay",
      prefill: "Ajay",
      rules: [{ required: true, message: "Please input your first name!" }],
    },
    {
      label: "Last Name",
      name: "lastName",
      placeholder: "eg. Kumar",
      prefill: "Kumar",
      rules: [{ required: true, message: "Please input your last name!" }],
    },
    {
      label: "Phone",
      name: "phone",
      placeholder: "6230123405",
      prefill: "6230123405",
      rules: [{ required: true, message: "Please input your phone number!" }],
      addonBefore: "+91",
    },
    {
      label: "Email Id",
      name: "email",
      prefill: "example@email.com",
      placeholder: "example@email.com",
      rules: [{ required: true, message: "Please input your email!" }],
    },
    {
      label: "Date of Birth",
      name: "dateOfBirth",
      placeholder: "DD-MM-YYYY",
      prefill: "01-01-1950",
      rules: [{ required: true, message: "Please input your date of birth!" }],
    },
    {
      label: "Gender",
      name: "gender",
      placeholder: "Select",
      prefill: "Male",
      rules: [{ required: true, message: "Please select your gender!" }],
      options: [
        { value: "male", label: "Male" },
        { value: "female", label: "Female" },
        { value: "other", label: "Other" },
      ],
    },
  ];

  return (
    <div className="fill-details">
      <div className="container">
        <StandardHealthPlan />
        <Form
          form={form}
          layout="vertical"
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
          className="personal-details-form"
        >
          <div className="personal-details">
            <Title level={3} className="title">
              Fill Personal Details
            </Title>
            {formItems.map((item, idx) => (
              <Form.Item
                key={idx}
                label={item.label}
                name={item.name}
                rules={item.rules}
                initialValue={item.prefill}
              >
                {item.options ? (
                  <Select placeholder={item.placeholder} defaultValue={"Male"}>
                    {item.options.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  <Input
                    placeholder={item.placeholder}
                    addonBefore={item.addonBefore}
                  />
                )}
              </Form.Item>
            ))}
          </div>

          {spouseDetails.map((spouse: any, index: number) => (
            <FamilyMemberDetails
              key={index}
              type="spouse"
              index={index}
              form={form}
            />
          ))}

          {childDetails.map((child: any, index: number) => (
            <FamilyMemberDetails
              key={index}
              type="child"
              index={index}
              form={form}
            />
          ))}
          <div className="submit-button">
            <div className="add-buttons">
              {spouseDetails.length < 1 && (
                <button
                  type="button"
                  onClick={handleSpouseAdd}
                  disabled={spouseDetails.length >= 1}
                  className="add-spouse"
                >
                  Spouse <span className="plus">+ Add</span>
                </button>
              )}
              <button
                type="button"
                onClick={handleChildAdd}
                disabled={childDetails.length >= 3}
                className="add-child"
              >
                Child <span className="plus">+ Add</span>
              </button>
            </div>
            <Form.Item wrapperCol={{}}>
              <button className="make-payment" onClick={onFinish}>
                Make Payment
              </button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default FillDetails;
